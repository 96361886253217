import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)

Vue.use(VueCookies)

Vue.$cookies.config('365d')

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

import firebase from './firebase';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDQe-tqiXNoIpxOe1BvSONe-jD4yB8yHck',

  },
  region: 'IT',
  language: 'it',
  // installComponents: false
})

// import moment from 'moment'
// moment().format('LLLL');

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)


import './plugins/heroicons';
import './assets/styles/index.css';

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

import store from './store'


let app: Vue;

firebase.auth().onAuthStateChanged(() => {
  if(!app) {
    new Vue({
      router,
      store,
      // vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})

