<template>
  <header id="nav-bar" class="fixed flex items-center justify-between fixed w-full left-0 px-6 lg:px-12 box-border">
    <router-link tag="div" :to="{ name: 'Home' }" class="cursor-pointer flex items-center">
      <img class="inline-block" src="/img/pittogramma-lomarco-sviluppatore-web.svg" alt="Logo Gianluca Lomarco developer $ web designer" height="36" width="36">
      <span class="inline-block ml-6 font-bold">Home</span>
    </router-link>

    <div class="text-right flex justify-end items-center w-full">

      <!--<router-link tag="span" :to="{ name: 'Compare' }" class="font-bold mr-12 cursor-pointer hover:text-blue hidden md:inline">Confronta preventivo</router-link>-->
      <router-link tag="span" :to="{ name: 'Ask' }" class="font-bold mr-12 cursor-pointer hover:text-blue hidden md:inline">Chiedi Preventivo</router-link>
      <!--<router-link v-if="routeName == 'Blog'" tag="span" :to="{ name: 'Home' }" class="font-bold mr-12 cursor-pointer hover:text-blue">Home</router-link>-->
      <router-link tag="span" :to="{ name: 'Blog' }" class="font-bold mr-12 cursor-pointer hover:text-blue">Blog</router-link>

      <!--<tasty-burger-button size="xs" color="#000" v-on:toggle="showMenu = !showMenu" type="spin" />-->
      <button class="">
        <heroicon stroke="#000" stroke-width="2" @click="showMenu = !showMenu" name="menu-alt-1"></heroicon>
      </button>

    </div>

    <transition name="slide-down">
      <main-menu class="fixed" v-on-clickaway="away" v-on:hide="showMenu = false" v-if="showMenu"></main-menu>
    </transition>


  </header>
</template>

<script>
  import MainMenu from '../components/MainMenu'
  import { mixin as clickaway } from 'vue-clickaway';
  // import { TastyBurgerButton } from 'vue-tasty-burgers'

  export default {
    name: "NavBar",
    mixins: [ clickaway ],
    data() {
      return {
        showMenu: false
      }
    },
    components: {
      MainMenu,
      // TastyBurgerButton,
    },
    methods: {
      away() {
        this.showMenu = false;
      }
    },
  }
</script>

<style scoped>

  header {
    min-height: 95px;
    z-index: 150;
  }

  .slide-down-enter-active {
    transition: all .3s ease;
  }
  .slide-down-leave-active {
    transition: all .3s ease;
  }
  .slide-down-enter, .slide-down-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-80px);
    opacity: 0;
  }
</style>