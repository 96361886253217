<template>
  <div id="app" class="select-none overflow-hidden">
    <nav-bar v-if="!this.$router.currentRoute.fullPath.includes('start')"></nav-bar>
    <!--done togliere appear per vedere di risolvere lo scatto iniziale-->
    <transition name="slide-right">
      <router-view :key="$route.name + ($route.params.slug || '')"  v-on:before-route-leave="beforeRouteLeave" class="min-h-screen absolute top-0 left-0 w-full overflow-hidden"></router-view>
    </transition>


    <bottom-bar v-if="!this.$router.currentRoute.fullPath.includes('dashboard') && !this.$router.currentRoute.fullPath.includes('start') && !this.$router.currentRoute.fullPath.includes('3d-web')"></bottom-bar>

  </div>
</template>

<script>
  import NavBar from './components/NavBar'
  import BottomBar from './components/BottomBar'
  import "firebase/analytics";
  import { tsParticles } from "tsparticles";


  export default {
    components: {
      NavBar,
      BottomBar,
    },
    metaInfo() {
      return {
        meta: [
          { name: "google-site-verification", content: "QkOfX5r64jbgnxAgmQSZTjaJYcDKYisVxpgAsejl1C8" } //credo non serva questo
        ]
      }
    },
    data() {
      return {
        routeName: this.$router.currentRoute.name,
        currentTitle: '',
        particles: null,
        particlesConfig: {
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                valueArea: 800
              }
            },
            color: {
              value: ['FF8A65','039BE5','71CDE1','FF601C','E4E4E4'],
              animation: {
                enable: true,
                speed: 20,
                sync: true
              }
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000"
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 0.4,
              // random: true,
              anim: {
                enable: true,
                speed: 0.5,
                opacityMin: 0.1,
                sync: false
              }
            },
            size: {
              value: 6,
              random: true,
              anim: {
                enable: true,
                speed: 2,
                sizeMin: 0.1,
                sync: false,
              }
            },
            lineLinked: {
              enable: false
            },
            move: {
              enable: true,
              speed: 1,
              direction: "none",
              random: false,
              straight: false,
              outMode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              },
            }
          },
          interactivity: {
            detectOn: "window",
            events: {
              onHover: {
                enable: true,
                parallax: {
                  enable: true,
                  force: 2,
                  smooth: 10
                }
              },
              onClick: {
                enable: false,
                mode: "push"
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 400,
                lineLinked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 0.8,
                speed: 3
              },
              repulse: {
                distance: 200
              },
              push: {
                particlesNb: 4
              },
              remove: {
                particlesNb: 2
              },
              trail: {
                delay: 0.005,
                quantity: 5
              }
            }
          },
          retinaDetect: true,
          fpsLimit: 30,
        },
      }
    },
    methods: {
      beforeRouteLeave() {
        // setTimeout(this.resetClose(),100)
      },
      particlesPlay() {
        // this.particles.play()
      }
    },
    computed: {
      showNav: function() {
        const path = this.$router.currentRoute.fullPath;

        //console.log(this.$router.currentRoute.fullPath)


        if( this.$router.currentRoute.fullPath.includes('dashboard') || this.$router.currentRoute.fullPath.includes('start') ) {
          return false;
        } else {
          return true;
        }

      }
    },
    mounted() {
      document.dispatchEvent(new Event('render-ready'));
    },
    created() {
      this.close = false
      this.$store.dispatch('bindWebsites');
      this.$store.dispatch('bindSkills');
      this.$store.dispatch('bindKeywords');
      this.$store.dispatch('bindReviews');
      this.$store.dispatch('bindCourses');

      // this.particles = tsParticles.domItem(0)
      // this.particlesPlay();
      // if(window.innerWidth > 768) {
      //   tsParticles.load("tsparticles", this.particlesConfig);
      // }


    },
    beforeCreate() {
      document.addEventListener('DOMContentLoaded', (event) => {
        document.dispatchEvent(new Event('mounted'))
      });

      // tsParticles.load("tsparticles", this.particlesConfig)
    }
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.slide-right-enter-active {
  transition: all .6s ease;
  z-index: 50;
}
.slide-right-leave-active {
  transition: all .6s ease;
  z-index: -50;
}
.slide-right-enter {
  transform: translateX(-160px);
  opacity: 0;
}

.slide-right-leave-to {
  transform: translateX(0px);
  opacity: 0;

}

</style>
