<template>
  <section id="bottom-bar" class="fixed pointer-events-none bottom-0 left-0 w-full py-6 pr-6 lg:pr-12 pl-6 lg:pl-12 text-left">

    <transition name="slide-up" class="">
      <auth-form v-on-clickaway="awayForm" class="text-center pointer-events-all" v-if="showForm"></auth-form>
    </transition>

    <div class="bar pointer-events-none w-full flex justify-between items-center">
      <button @click="showForm = !showForm" class="rounded-full bg-white px-3 py-3 pointer-events-all shadow-2xl">
        <heroicon :stroke="showForm ? '#039BE5' : '#000'" stroke-width="2"  name="dots-vertical"></heroicon>
      </button>

      <transition name="slide-up">
        <button @click="showPreference = !showPreference" v-if="acceptance" class="rounded-full pointer-events-all bg-white px-3 py-3 shadow-2xl">
          <heroicon :fill="showPreference ? '#039BE5' : '#000'" stroke-width="0" viewBox="0 0 20 20"  name="adjustaments"></heroicon>
        </button>
      </transition>

    </div>

    <cookie-banner v-on:accept="enablePlugin" class="pointer-events-all" :acceptance="acceptance"></cookie-banner>

    <transition name="slide-up">
      <div v-on-clickaway="awayPreference" v-if="acceptance && showPreference" id="cookie-preference" class="fixed pointer-events-all right-0 bottom-0 mr-3 md:mr-12 text-right shadow-2xl bg-white px-8 py-8">
        <p class="font-bold mb-4 text-right text-button">Cookies</p>
        <p class="text-right text-button mb-4">Hai acconsentito all'utilizzo di cookie o tecnologie simili per le funzionalità fornite da questo sito.</p>

        <button @click="disablePlugin" class="py-1 px-3 rounded-full text-button bg-grey hover:text-white hover:bg-orange">Ci ho ripensato.</button>
      </div>
    </transition>
  </section>
</template>


<script>
  import { authMixin } from '../mixins/authMixin'
  import AuthForm from '../components/Auth'
  import CookieBanner from '../components/CookieBanner'
  import { mixin as clickaway } from 'vue-clickaway';
  import firebase from 'firebase/app';
  import "firebase/analytics";
  import { gsap } from "gsap";
  import { ScrollTrigger } from 'gsap/ScrollTrigger'
  gsap.registerPlugin(ScrollTrigger);


  export default {
    name: "BottomBar",
    mixins: [authMixin,clickaway],
    components: {
      AuthForm,
      CookieBanner
    },
    data() {
      return {
        showForm: false,
        analytics: null,
        acceptance: true,
        showPreference: false
      }
    },
    methods: {
      async enablePlugin () {
        this.acceptance = true

        if(!this.analytics) {
          this.$cookies.set("analytics",true, 60 * 60 * 24 * 365);
          this.analytics = true;
        }

        firebase.analytics().setAnalyticsCollectionEnabled(true)
        // this.$analytics.setAnalyticsCollectionEnabled(true)
      },
      awayForm() {
        this.showForm = false;
      },
      awayPreference() {
        this.showPreference = false;
      },
      disablePlugin() {

        if( confirm('Confermi di volere ritirare il tuo consenso sui cookies installati dal mio sito web?  Così rinuncerai ad alcuni interessanti funzionalità.') ) {
          this.$cookies.remove("analytics")
          // firebase.analytics().setAnalyticsCollectionEnabled(false)

          document.location.reload()
        }

      }
    },
    beforeMount() {

      if (window.__prerender) { // <---- hey, relax, it’s prerender
        this.acceptance = false
      } else {
        this.analytics = this.$cookies.get('analytics');

        if(this.analytics) {
          this.enablePlugin()
        } else {
          this.acceptance = false
        }
      }

    },
    mounted() {
      gsap.to('.bar',{
        scrollTrigger: {
          trigger: "#app",
          toggleActions: 'restart none reverse none',
          start: "500 top",
          // markers: true,
          // onEnter: ({progress, direction, isActive}) => console.log(progress, direction, isActive)
        }, // start the animation when ".box" enters the viewport (once)
        y: 200,
        opacity: 0,
        duration: 1,
        ease: "sine.inOut",

      })
    }

  }
</script>

<style scoped>

  #bottom-bar {
    z-index: 101;
  }

  .slide-up-enter-active {
    transition: all .3s ease;
  }
  .slide-up-leave-active {
    transition: all .3s ease;
  }
  .slide-up-enter, .slide-up-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(80px);
    opacity: 0;
  }

  #cookie-preference {
    bottom: 80px;
    max-width: 350px;
  }

  .pointer-events-all {
    pointer-events: all;
  }
</style>