import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import * as firebase from 'firebase/app'
import 'firebase/auth';
// import Home from '../views/Home.vue'

function page (path: string) {
  return () => import(/* webpackChunkName: '' */ `../views/${path}`).then(m => m.default || m)
}

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: page('Home.vue')
  },
  {
    path: '/siti-web/:slug',
    name: 'Website.show',
    component: page('Website.vue')
  },
  {
    path: '/blog/:slug',
    name: 'Post',
    component: page('Post.vue')
  },
  {
    path: '/recensioni',
    name: 'Reviews',
    component: page('Reviews')
  },
  {
    path: '/realizzazione-siti-web',
    name: 'Websites',
    component: page('Websites')
  },
  {
    path: '/realizzazione-web-app',
    name: 'WebApps',
    component: page('Webapps')
  },
  {
    path: '/realizzazione-app-mobile',
    name: 'MobileApps',
    component: page('Mobileapps')
  },
  {
    path: '/sito-web-personalizzato',
    name: 'CustomWebsite',
    component: page('CustomWebsite')
  },
  {
    path: '/formazione',
    name: 'Training',
    component: page('Training')
  },
  {
    path: '/contatti',
    name: 'Contact',
    component: page('Contact')
  },
  {
    path: '/chiedi-un-preventivo',
    name: 'Ask',
    component: page('Ask')
  },
  // {
  //   path: '/confronta-preventivi',
  //   name: 'Compare',
  //   component: page('Compare')
  // },
  {
    path: '/games',
    component: page('Games'),
    children: [
      {
        path: '',
        name: 'Game',
        component: page('console/GamesDashboard'),
      },
      {
        path: 'start/:slug',
        name: 'StartGame',
        component: page('console/StartGame.vue')
      }
    ]
  },
  {
    path: '/blog',
    name: 'Blog',
    component: page('Blog')
  },
  {
    path: '/dashboard',
    component: page('backend/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: page('backend/HomeDashboard.vue'),
      },
      {
        path: 'skills',
        name: 'Manage.skills',
        component: page('backend/Skills.vue'),
      },
      {
        path: 'posts',
        name: 'Manage.posts',
        component: page('backend/Posts.vue'),
      },
      {
        path: 'leads',
        name: 'Manage.leads',
        component: page('backend/Leads.vue'),
      },
      {
        path: 'quotations',
        name: 'Manage.quotations',
        component: page('backend/Quotations.vue'),
      },
      {
        path: 'siti-web',
        name: 'Manage.websites',
        component: page('backend/Websites.vue'),
      },
      {
        path: 'reviews',
        name: 'Manage.reviews',
        component: page('backend/Reviews.vue'),
      },
      {
        path: 'keywords',
        name: 'Manage.keywords',
        component: page('backend/Keywords.vue'),
      },
      {
        path: 'courses',
        name: 'Manage.courses',
        component: page('backend/Courses.vue'),
      },
      {
        path: 'games',
        name: 'Manage.games',
        component: page('backend/Games.vue'),
      }
    ],
    meta: {
      requireAuth: true,
    }
  },
  {
    path: '/3d-webgl',
    name: 'ThreeD',
    component: page('ThreeD'),

  },
  {
    // catch all 404 - define at the very end
    path: "*",
    name: 'NotFound',
    component: page('404.vue')
  },

//  redirects
  {
    path: '/game',
    component: page('Games'),
    children: [
      {
        path: '',
        component: page('console/GamesDashboard'),
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.requireAuth);
  const isAuthenticated = firebase.auth().currentUser;

  if(requireAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }

});

router.beforeEach((to, from, next) => {
  const requireGuest = to.matched.some(record => record.meta.requireGuest);
  const isAuthenticated = firebase.auth().currentUser;

  if(requireGuest && isAuthenticated) {
    next('/');
  } else {
    next();
  }

});

export default router
