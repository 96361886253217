<template>
  <div class="fixed max-w-sm mr-6 lg:mr-12 bg-white overflow-hidden shadow-2xl menu-card">
    <div class="px-6 py-4 text-right tracking-widest ">
      <ul class="main-menu-list" @click="hide">
        <li><router-link tag="a" :to="{ name: 'Home' }" alt="Torna alla Home page">home</router-link></li>
        <li><router-link tag="a" :to="{ name: 'Websites' }" alt="Vai alla pagina dei siti web">siti web</router-link></li>
        <li><router-link tag="a" :to="{ name: 'WebApps' }" alt="Vai alla pagina delle web app">web app</router-link></li>
        <li><router-link tag="a" :to="{ name: 'MobileApps' }" alt="Vai alla pagina delle app mobile">app mobile</router-link></li>
        <li><router-link tag="a" :to="{ name: 'ThreeD' }" alt="Vai alla pagina sulla modellazione 3D">3D</router-link></li>
        <li><router-link tag="a" :to="{ name: 'Reviews' }" alt="Vai alla pagina delle recensioni">recensioni</router-link></li>
        <li><router-link tag="a" :to="{ name: 'Training' }" alt="Vai alla pagina sulla formazione">formazione</router-link></li>
        <li><router-link tag="a" :to="{ name: 'Game' }" alt="Vai alla pagina dei giochi">game</router-link></li>
        <li><router-link tag="a" :to="{ name: 'Contact' }" alt="Vai alla pagina dei contatti">contatti</router-link></li>
        <!--<li><router-link class="md:hidden" tag="a" :to="{ name: 'Compare' }" alt="Vai alla pagina confronta preventivo">Confronta preventivo</router-link></li>-->
        <li><router-link class="md:hidden" tag="a" :to="{ name: 'Ask' }" alt="Vai alla pagina chiedi un preventivo">Chiedi preventivo</router-link></li>
        <li><router-link v-if="loggedIn" tag="a" :to="{ name: 'Dashboard' }" alt="Vai alla dashboard">Dashboard</router-link></li>
        <li class="mt-6">
          <a href="https://www.facebook.com/lomarcoweb/" title="Vai alla pagina facebook di Gianluca Lomarco" target="_blank" class="text-blue text-lg font-bold mx-8">f</a>
          <a href="https://www.linkedin.com/in/gianluca-lomarco-2a496b5b/" target="_blank" title="Vai al profilo facebook di Gianluca Lomarco" class="text-cyan text-lg font-bold">in</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { authMixin } from '../mixins/authMixin'

  export default {
    name: "MainMenu",
    mixins: [authMixin],
    data() {
      return {

      }
    },
    methods: {
      hide() {
        this.$emit('hide')
      }
    }
  }
</script>

<style scoped>

  .menu-card {
    min-width: 255px;
    top: 95px;
    right: 0px
  }

  .text-right li {
    text-align: right!important;
    font-weight: 700;
  }
</style>