import * as firebase from 'firebase/app'
import 'firebase/auth';

export const authMixin = {
  data() {
    return {
      loggedIn: false,
      userCanRegister: false,
    }
  },
  methods: {
    async login() {
      try {
        await firebase.auth().signInWithEmailAndPassword(this.email,this.password);
        this.$router.replace({name: 'Dashboard'});
      } catch(err) {
        console.error(err);
      }
    },


    async register() {

      try {
        await firebase.auth().createUserWithEmailAndPassword(this.email,this.password);
        this.$router.replace({name: 'Dashboard'});
      } catch(error) {
        //console.log(error);
      }

  },


    async logout() {

      try {
        await firebase.auth().signOut();
        this.$router.replace({name: 'Home'});
      } catch(err) {
        console.error(err);
      }

    },

  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      this.loggedIn = !!user;
    })
  }
}