import Vue from 'vue'
import firebase from 'firebase/app';
import "firebase/analytics";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC9vTZ8t27Y0HZ0GZJy9IVUOYDkkYqevQw",
    authDomain: "gianluca-lomarco.firebaseapp.com",
    databaseURL: "https://gianluca-lomarco.firebaseio.com",
    projectId: "gianluca-lomarco",
    storageBucket: "gianluca-lomarco.appspot.com",
    messagingSenderId: "594654073863",
    appId: "1:594654073863:web:78b02145547085702106a2",
    measurementId: "G-2LFZ54NP2G"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics()

Vue.config.productionTip = false

// alias
// Vue.prototype.$analytics = firebase.analytics();

export default firebase;