import Vue from 'vue'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import Vuex from 'vuex'
import { db} from '../db'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    websites: [
      {
        id: '',
        name: '',
        avatar: '',
        content: '',
        icon: ''
      }
    ],
    reviews: [],
    skills: [],
    keywords: [],
    posts: [
      {
        id: '',
        title: '',
        content: '',
        cats: [''],
      }
    ],
    users: [],
    leads: [],
    courses: [],
    games: [],
    allGames: [],
    gameLoop: {
      paused: false,
      running: false,
      loop: null,
      fps: 25,
    },
    gameSettings: {
      sound: {
        effect: {
          volume: 0.6,
        },
        background: {
          volume: 0.15,
        },
      },
      difficulty: {
        name: 'medium',
        hitRange: 250,
        increments: {
          enemy: {
            damage: -10,
            health: -50,
            speed: 0,
            shield: 50,
            resistance: -10,
            rate: 0,
            level: 0,
          }
        }
      },
    }
  },
  getters: {
    websites: state => state.websites,
    websiteById: (state) => (id: string) => state.websites.find(el => el.id === id),
    postById: (state) => (id: string) => state.posts.find(el => el.id === id),
    reviews: state => state.reviews,
    skills: state => state.skills,
    keywords: state => state.keywords,
    posts: state => state.posts,
    leads: state => state.leads,
    courses: state => state.courses,
    users: state => state.users,
    games: state => state.games,
    gameloop: state => state.gameLoop,
    gameSettings: state => state.gameSettings,
    allGames: state => state.allGames
  },
  mutations: {
    ...vuexfireMutations,
  },
  actions: {
    bindWebsites: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('websites', db.collection('websites').orderBy('name','asc'))
    }),
    bindReviews: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('reviews', db.collection('reviews'))
    }),
    bindSkills: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('skills', db.collection('skills').orderBy('name','asc'))
    }),
    bindPosts: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('posts', db.collection('posts'))
    }),
    bindKeywords: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('keywords', db.collection('keywords'))
    }),
    bindLeads: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('leads', db.collection('leads').orderBy('timestamp','desc'))
    }),
    bindCourses: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('courses', db.collection('courses'))
    }),
    bindGames: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('games', db.collection('games').where('public','==',true))
    }),
    bindAllGames: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('allGames', db.collection('games'))
    }),
  },
  modules: {
  }
})
