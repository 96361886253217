<template>


    <div class="max-w-sm overflow-hidden shadow-2xl mb-4">

      <div v-if="loggedIn" class="px-6 py-4 bg-white">
        <button @click.prevent="logout" class="text-black">Logout</button>
      </div>
      <div v-else class="px-6 py-4 bg-white">
        <h3 v-if="userNeedLogin" class="mb-4 text-sm font-bold">Login</h3>
        <h3 v-else-if="userCanRegister" class="mb-4 text-sm font-bold">Register</h3>

        <form @submit.prevent="submit" class="text-center">

          <input
            type="email"
            v-model="email"
            placeholder="your email"
            class="shadow appearance-none border-gray-500 rounded w-full mb-4 py-2 px-3">

          <input
            type="password"
            v-model="password"
            placeholder="password"
            class="shadow appearance-none border-gray-500 rounded w-full mb-4 py-2 px-3">

          <input
            v-if="!userNeedLogin"
            type="password"
            v-model="passwordConfirmed"
            placeholder="password"
            class="shadow appearance-none border-gray-500 rounded w-full mb-4 py-2 px-1"
          >

          <button class="bg-blue hover:bg-blue-700 text-white text-button font-bold py-1 px-3 rounded-full" type="submit">{{ userNeedLogin ? 'Login' : 'Create' }}</button>
        </form>

        <div v-if="userCanRegister">
          <span @click="toggleForm">i have an account</span>
          <span @click="toggleForm">create an account</span>
        </div>
      </div>
    </div>


</template>

<script>
  // import * as firebase from 'firebase/app'
  // import 'firebase/auth';
  import { authMixin } from '../mixins/authMixin'

  export default {
    name: "Auth",
    data() {
      return {
        email: '',
        password: '',
        passwordConfirmed: '',
        error: '',
        userNeedLogin: true
      }
    },
    mixins: [authMixin],
    methods: {
      async submit() {
        //form validation
        if(this.userNeedLogin) {
          this.login();
        } else {
          this.register();
        }
      },
      toggleForm() {
        if(this.userCanRegister) {
          this.userNeedLogin = !this.userNeedLogin;
        }
      }
    },
    created() {
      // firebase.auth().onAuthStateChanged((user) => {
      //   this.loggedIn = !!user;
      // })
    }
  }
</script>

<style scoped>

  button {
    /*color: white;*/
  }
</style>