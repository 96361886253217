<template>
  <div class="modal-container fixed w-screen left-0 bottom-0 pb-0 md:pb-10 ">
    <transition name="slide-up">
      <div v-if="!acceptance && !prerender" class="modal flex relative max-w-sm overflow-hidden bg-blue text-white shadow-2xl menu-card px-8 py-8">

        <div class="pt-4 w-full">
          <h3 class="font-bold text-caption mb-3">Cookies Banner</h3>
          <p class="text-button mb-4">Io e alcuni partner selezionati utilizziamo cookie o tecnologie simili come specificato nella <strong ><a
            href="/cookie-policy.html" class="text-black" target="_blank" title="Vai alla Cookie Policy">cookie policy.</a></strong>
            Puoi acconsentire all’utilizzo di tali tecnologie chiudendo questa informativa o <strong>cliccando sul pulsante accetto.</strong></p>

          <button @click="accept" class="rounded-full bg-white text-blue px-4 py-2 text-button font-bold">Accetto</button>
        </div>
        <div class="text-right mb-1 cursor-pointer ml-4">
          <heroicon @click="accept" height="20" width="21" viewBox="0 0 21 20" fill="#fff" stroke-width="0" name="x"></heroicon>
        </div>

      </div>
    </transition>

  </div>
</template>

<script>
  export default {
    name: "CookieBanner",
    props: {
      acceptance: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        analytics: null,
        prerender: window.__prerender, // <---- hey, relax, it’s prerender
      }
    },
    methods: {
      accept() {
        this.$emit('accept')
      }
    }
  }
</script>

<style scoped>
  .modal-container {
    pointer-events: none;
    z-index: 999999;
  }

  .modal {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    pointer-events: all;
    z-index: 99999;
  }

  .timing-bar {
    height: 4px;
    width: 100%;
  }

  .slide-up-enter-active {
    transition: all .6s ease;
  }
  .slide-up-leave-active {
    transition: all .6s ease;
  }
  .slide-up-enter, .slide-up-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(100px);
    opacity: 0;
  }
</style>